// Sourced ethically from Flugger's Sharepoint using GPT 4V inside a Bun runtime 🤝
// https://flugger.sharepoint.com/:w:/r/teams/POSRetail/_layouts/15/Doc.aspx?sourcedoc=%7BE37EDD01-A364-47CA-B39B-8E1522FDDCF7%7D&file=POS-75%20US%20Costumer%20freight%20agreement.docx&action=default&mobileredirect=true

export const virtualShippingProducts = [
    {
        item: 81090,
        name: 'Fragt Express og Tidslevering (Lille bil)',
        fld: true,
        fli: false,
        fln: true,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81091,
        name: 'Fragt i dag økonomi (Lille bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81092,
        name: 'Fragt i morgen inden 10:00 (Lille bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81093,
        name: 'Fragt i dag til dag (Lille bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: true,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81094,
        name: 'Fragt Express og Tidslevering (Medium bil)',
        fld: true,
        fli: false,
        fln: true,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81095,
        name: 'Fragt i dag økonomi (Medium bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81096,
        name: 'Fragt i morgen inden 10:00 (Medium bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81097,
        name: 'Fragt i dag til dag (Medium bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: true,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81098,
        name: 'Fragt direkte levering (Medium bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81099,
        name: 'Fragt Express og Tidslevering (Stor bil)',
        fld: true,
        fli: false,
        fln: true,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81100,
        name: 'Fragt i dag økonomi (Stor bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81101,
        name: 'Fragt i morgen inden 10:00 (Stor bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81102,
        name: 'Fragt i dag til dag (Stor bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: true,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81103,
        name: 'Fragt i dag til dag (Stor bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81104,
        name: 'Fragt direkte levering (Stor bil)',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Used WEB orders',
    },
    {
        item: 81105,
        name: 'Fragttillæg over 40 km.',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Used WEB orders',
    },
    {
        item: 88830,
        name: 'Danske Fragtmænd Stykgods',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Special DK',
    },
    {
        item: 90007,
        name: 'Container fragt ind/ud',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Special, rent of container',
    },
    {
        item: 98306,
        name: 'Fragt/ekspeditionstillæg 06',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Item closed',
    },
    {
        item: 98308,
        name: 'Fragt/ekspeditionstillæg 08',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Item closed',
    },
    {
        item: 98334,
        name: 'Fragt/ekspeditionstillæg 34',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Item closed',
    },
    {
        item: 98335,
        name: 'FRAGT/PORTO E-COMMERCE',
        fld: false,
        fli: false,
        fln: false,
        flp: false,
        fls: false,
        comment: 'Item closed',
    },
    {
        item: 98399,
        name: 'Fragt/forsendelse',
        fld: true,
        fli: false,
        fln: true,
        flp: true,
        fls: true,
        comment: 'If needed freight at order',
    },
    {
        item: 98500,
        name: 'Fragt & ekspeditionstgebyr',
        fld: true,
        fli: false,
        fln: false,
        flp: false,
        fls: true,
        comment: 'Auto freight',
    },
];
