import type { TokenPayload } from '~/types';

// Ideally I would like it to NOT be null after initialized upon app first load
// so I don't have to throw "!" everywhere in the code.
let token: TokenPayload;

export function useToken() {
    // If already initialized, return the token
    if (token) {
        return token;
    }

    // Otherwise, initialize the token

    // Parse JWT token from jwt cookie
    const tokenFromCookie = useCookie('fljwt');
    const tokenFromUrl = new URLSearchParams(window.location.search).get(
        'token',
    );
    const tokenString = tokenFromCookie.value || tokenFromUrl;

    if (!tokenString) {
        throw showError({
            fatal: true,
            statusCode: 401,
            statusMessage: 'You may only pass through with valid JWT token.',
        });
    }

    try {
        // Decode JWT token
        token = JSON.parse(atob(tokenString.split('.')[1]));

        // Assign to window object for debugging
        // ToDo: remove upon complete production rollout
        (window as any).token = token;

        // Maybe I should validate the props such as currency, ids here too...
    } catch (e) {
        throw showError({
            fatal: true,
            statusCode: 401,
            statusMessage: 'Invalid JWT token.',
        });
    }

    return token;
}
