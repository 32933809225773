export const enum Countries {
    Denmark = 'DK',
    Sweden = 'SE',
    Norway = 'NO',
    Finland = 'FI',
    Germany = 'DE',
    Poland = 'PL',
    Lithuania = 'LT',
    Latvia = 'LV',
    Estonia = 'EE',
}

export const CountryName = {
    [Countries.Denmark]: 'Denmark',
    [Countries.Sweden]: 'Sweden',
    [Countries.Norway]: 'Norway',
    [Countries.Finland]: 'Finland',
    [Countries.Germany]: 'Germany',
    [Countries.Poland]: 'Poland',
    [Countries.Lithuania]: 'Lithuania',
    [Countries.Latvia]: 'Latvia',
    [Countries.Estonia]: 'Estonia',
};

export const enum PaymentProvider {
    Adyen = 'adyen',
    Flugger = 'fl',
}

export const enum PaymentMethod {
    PayByLinkEmail = 'paymentlink_email',
    PayByLinkSms = 'paymentlink_sms',
    CustomerCredit = 'customer_credit',
}
