import { Countries, PaymentMethod, PaymentProvider } from '~/static';
import type { CustomerType } from '~/types';

const addressDefault = {
    // Contact details
    firstName: '',
    lastName: '',
    phoneNumber: null,
    //
    // Company details (optional)
    companyName: '',
    vatNumber: '',
    //
    // Address data
    street: '',
    address2: '',
    zipCode: '',
    city: '',
    countryCode: Countries.Denmark,
};

const customer = useState<CustomerType>('customer-data', () => ({
    email: '',

    flags: {
        canCustomerOrderFromMainSiteWebshipper: true,
        isShippingPaidAsOrderLine: false,
        // B2B Customers may be able to pay by invoice if CreditRemaining > TotalAmount
        isPayByInvoicePermitted: false,
    },

    webshipperData: {
        shippingRateId: null,
        // Set shippingRatePrice to null if the order has the virtual shipping entry;
        shippingRatePrice: null,
        dropPointId: null,
    },

    checkoutPaymentDetails: {
        provider: PaymentProvider.Adyen,
        method: PaymentMethod.PayByLinkEmail,
        email: '',
    },

    billing: addressDefault,
    delivery: addressDefault,

    orderComment: '',
    shippingComment: '',

    activeProject: null,
}));

export default function () {
    return customer;
}
