import type { Receipt } from '~/types';

export default async function (): Promise<Receipt> {
    if (process.server) {
        throw new Error('`getActiveOrder.ts` must be run client side.');
    }

    // IMPORTANT: Must always run client side.
    const response = await fetch('http://127.0.0.1:8888/api/v2/Receipt', {
        headers: {
            Accept: 'application/json',
        },
    });

    const data: Receipt = await response.json();

    return data;
}
