import getActiveOrder from '~/functions/getActiveOrder';
import type { EnrichedOrderLine, Receipt } from '~/types';
import { virtualShippingProducts } from '~/assets/virtualShippingProducts';

let order: Receipt | null = null;
let enrichedOrderlines = ref<EnrichedOrderLine[]>([]);
let flCustomerId: string | null = null;

export async function useActiveOrder() {
    // Only initialize the order once
    if (order === null) {
        const { $sentrySetContext, $sentrySetTag } = useNuxtApp();
        const localOrder = await getActiveOrder();
        $sentrySetContext('order', localOrder);
        $sentrySetTag('pos_id', localOrder.ID);
        $sentrySetTag('employee_id', localOrder.Receipt.Header.salespersonid);
        $sentrySetTag('store_id', localOrder.Receipt.Header.store);
        $sentrySetTag('cashier_id', localOrder.Receipt.Header.cashier);

        // Check for virtual shipping product
        const itemNumbersOfVirtualShippingProducts: number[] =
            virtualShippingProducts.map(({ item }) => item);
        for (let i = 0; i < localOrder?.Receipt?.Lines?.length; i++) {
            if (
                itemNumbersOfVirtualShippingProducts.includes(
                    localOrder?.Receipt?.Lines[i]?.ArticleId
                )
            ) {
                localOrder.Receipt.Lines[i].Type = 'shipping';
            }
        }

        // TODO: DEV WORKAROUND: unset customer 800015 as this is a store cash account
        if (
            new RegExp('8000\\d\\d').test(
                localOrder?.Receipt?.Header?.customerId ?? ''
            )
        ) {
            console.log(
                'customer id: ' +
                    localOrder?.Receipt?.Header?.customerId +
                    ' filtered out as it looks like a store cash account'
            );
            localOrder.Receipt.Header.customerId = undefined;
        }

        order = localOrder;

        Object.freeze(order);
    }

    // Constuct the full customer ID to use downstream in Flüggers systems.
    // This is nessecerry because vendor FiftyTwo can only handle customer
    // and project ids as integer, thus leading zeros are removed.
    if (order?.Receipt.Header?.customerId && order?.Receipt.Header?.projectId) {
        var projectId = String(order.Receipt.Header.projectId);
        while (projectId.length < 5) {
            projectId = '0' + projectId;
        }
        flCustomerId =
            String(order.Receipt.Header.customerId) + String(projectId);
    } else if (order?.Receipt.Header?.customerId) {
        flCustomerId = String(order.Receipt.Header.customerId);
    }

    return {
        order,
        isReceiptEmpty:
            order?.Receipt.Lines === null || order?.Receipt.Lines.length === 0,
        customerId: flCustomerId,
        setEnrichedOrderlines: (enriched: EnrichedOrderLine[]) => {
            enrichedOrderlines.value = enriched;
        },
        enrichedOrderlines,
    };
}
